import { ErrorCode } from '@/generated/payment-api';

export const unexpectedErrorMessage =
  '予期せぬエラーが発生しました。再度やり直してください。';

// FIXME: 全てのErrorCodeを網羅したい
export const errorMessageMap = new Map<ErrorCode, string>([
  [
    ErrorCode.ALREADY_RESTOCKED,
    '注文処理が正常に完了できませんでした。恐れ入りますが、一度応援購入履歴をご確認いただき、履歴にない場合のみ再度お申し込みください。',
  ],
  [
    ErrorCode.PAYMENT_SERVICE_AUTH_ERROR,
    '注文手続きが完了できません。発行元カード会社にご確認ください。',
  ],
  [
    ErrorCode.TDS_SYSTEM_ERROR,
    '本人認証が正常に完了できませんでした。再度やり直してください。',
  ],
  [
    ErrorCode.PAYMENT_SERVICE_TDS_AUTH_ERROR,
    '注文手続きが完了できません。再度やり直してください。',
  ],
  [
    ErrorCode.PAYMENT_SERVICE_SERVER_ERROR,
    'ただいまサイトが大変混み合っております。ご迷惑をおかけいたしますが、時間をおいて再度申込みをお願いいたします。',
  ],
  [ErrorCode.PAYMENT_SERVICE_INVALID_REQUEST, unexpectedErrorMessage],
  [ErrorCode.PAYMENT_SERVICE_TRANSACTION_ERROR, unexpectedErrorMessage],
  [ErrorCode.INTERNAL_SERVER_ERROR, unexpectedErrorMessage],
]);
